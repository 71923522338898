import React, { useEffect } from "react";
import FacilitiesPicker from "../facilitiesPicker";
import Select2 from "react-select2-wrapper";
import './EditUserModal.css';

const EditUserModal = ({
  isModalOpen,
  editUserName,
  editUserEmail,
  editUserId,
  editUserRoleId,
  origUserName,
  origUserRoleId,
  origGroupIds,
  groupIds,
  roles,
  setEditUserName,
  setEditUserRoleId,
  facilitiesChanged,
  setOrigGroupIds,
  inviteUserById,
  updateUser,
  setIsModalOpen,
  displayInvitationSentLabel,
}) => {

  // New function to handle initial data from FacilitiesPicker
  const handleFacilitiesInit = (initialRegions) => {
    if (!initialRegions || !Array.isArray(initialRegions)) return;

    const initialGroupIds = initialRegions.flatMap(region =>
      region.groups
        .filter(group => group.checked)
        .map(group => group.id)
    );

    setOrigGroupIds(initialGroupIds);
  };

  useEffect(() => {
    if (
      editUserName &&
      editUserEmail &&
      editUserRoleId &&
      editUserId &&
      isModalOpen === false
    ) {
      setIsModalOpen(true);
    }
  }, [editUserName, editUserEmail, editUserRoleId, editUserId]);

  if (!isModalOpen) return null;

  const isUpdateDisabled =
    editUserName === origUserName
    && String(editUserRoleId) === String(origUserRoleId)
    && JSON.stringify(groupIds) === JSON.stringify(origGroupIds);

  return (
    <div className="modal-overlay">
      <div className="modal-container">
        <header className="modal-header modal-header-edit-user">
          <h2>Edit user</h2>
          <button
            className="close-button"
            onClick={() => setIsModalOpen(false)}
            aria-label="Close"
          >
            &times;
          </button>
        </header>
        <form className="edit-user-form">
          {/* Name Field */}
          <div className="form-group section height-40-style light-style">
            <label>
              Name <span className="required"> *</span>
            </label>
            <input
              type="text"
              value={editUserName}
              onChange={(e) => setEditUserName(e.target.value)}
              placeholder="User name..."
            />
          </div>

          {/* Email Field */}
          <div className="form-group email-group section height-40-style light-style">
            <label>Email</label>
            <div className="input-wrapper">
              <input
                type="email"
                value={editUserEmail}
                readOnly
              />
              <div
                className="excl-mark"
                data-toggle="tooltip"
                data-offset="0 5"
                title=""
                data-original-title="This email is locked and cannot be edited. To update the user's email, you'll need to create a new user with the correct email address."
              >
                i
              </div>
            </div>
          </div>
          {/* Locations Picker */}
          <div className="form-group">
            <label>
              Locations <span className="required"> *</span>
            </label>
            <div className="section z-bigger-30 height-40-style light-style edit-user-form-dropdown">
              <FacilitiesPicker
                showAssignedLocations={true}
                userId={editUserId}
                subClassName={"select-location full background-blue-light"}
                className={"col-sm-13 pl-sm-0"}
                onChange={facilitiesChanged}
                onInit={handleFacilitiesInit}
              ></FacilitiesPicker>
            </div>

          </div>

          {/* Role Picker */}
          <div className="form-group">
            <label>
              Role <span className="required"> *</span>
            </label>
            <div className="section z-bigger-20 height-40-style light-style edit-user-form-dropdown">
              {roles && (
                <Select2
                  data={roles.map((x) => ({ id: x.id, text: x.name }))}
                  defaultValue={editUserRoleId}
                  options={{
                    placeholder: "Select a role",
                    theme: "bootstrap4",
                    minimumResultsForSearch: -1,
                  }}
                  onChange={(e) => setEditUserRoleId(e.target.value)}
                />
              )}
            </div>
          </div>

          {/* Password Reset Section */}
          <div className="form-group">
            <div className="password-reset-label">
              <label>
                Password Reset
              </label>
            </div>
            <div className="password-reset">
              <p>
                To reset the user’s password, click the link below. The user will
                receive an email with instructions on setting up a new password.
              </p>
              <>
                {displayInvitationSentLabel ? (
                  <div className="password-reset-invitation">
                    <span
                      className="invitation-sent"
                      onClick={() => inviteUserById(editUserId, editUserEmail)}
                    >
                      Password Reset Instructions Sent!
                    </span>
                  </div>
                ) : (
                  <div className="password-reset-invitation">
                    <span
                      className="password-reset-instructions"
                      onClick={() => inviteUserById(editUserId, editUserEmail)}
                    >
                      Send Password Reset Instructions
                    </span>
                  </div>
                )}
              </>
            </div>
          </div>

          {/* Actions */}
          <div className="form-actions">
            <button
              type="button"
              className="btn btn-white btn-width-80 btn-height-36 border-5 font-size-14 mr-1 mt-2 mt-xl-0"
              onClick={() => setIsModalOpen(false)}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-blue-gray btn-width-80 btn-height-36 border-4 font-size-14 mr-1 mt-2 mt-xl-0"
              onClick={updateUser}
              disabled={isUpdateDisabled}
            >
              Update
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditUserModal;