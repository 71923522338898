import React, { useEffect } from "react";
import $ from "jquery";
import { FaCheckCircle } from 'react-icons/fa'; 

const MessageModal = (props) => {
  const setAndShowPrompt = () => {
    if (props.promptMessage && props.promptMessage !== "" && props.promptMessage != null) {
      $('#modalPrompt').modal('show');
    }
  };

  useEffect(() => {
    return () => {
      $('#modalPrompt').modal('hide');
    };
  }, [props.promptMessage]);

  useEffect(
    setAndShowPrompt
  , [props.promptMessage]);

  return (
    <>
      <div
        className="modal fade"
        id="modalPrompt"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="modalCreateRole"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-success" role="document">
          <div className="modal-content modal-content-success">
            <div className="modal-header modal-header-success">
              <h6 className="modal-title font-weight-bold-success" id="modalPromptTitle">
                {props.promptTitle || "Operation Successful"}
              </h6>
            </div>
            <div className="modal-body modal-body-success">
              {props.promptMessage && (
                <div className="alert alert-success" role="alert" style={{ whiteSpace: "pre-line" }}>
                  <FaCheckCircle style={{ marginRight: '8px', fontSize: '1.2rem', color: '#04816B' }} />
                  {props.promptMessage}
                </div>
              )}
            </div>
            <div className="modal-footer modal-footer-success">
              <button
                type="button"
                data-dismiss="modal"
                aria-label="Close"
                className="btn btn-secondary"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MessageModal;