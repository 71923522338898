// Dependencies

// Helpers
import { PIMS_DETAILS, IMAGE_VALID_EXTENSIONS, IMAGE_ACCEPT_EXTENSIONS, IMAGE_MAX_SIZE_BYTES, IMAGE_MAX_WIDTH, IMAGE_MAX_HEIGHT } from "./constants";
import { ajaxCaller } from "../../ajaxCaller";
import { decode } from "tiff";
import Cookies from 'js-cookie';

// Function: createLabelByPimsTypeID

/**
 * Returns the text associated with a given PIMS type ID.
 * If the ID is not found in the settings, it returns the default text.
 *
 * @param {string} pimsTypeID - The PIMS type ID for which to look up the text.
 * @param {Object} settings - Object containing key-value pairs of ID and text, and a default value.
 * @param {string} settings.default - The default text to return if the ID is not found.
 * @returns {string} - The text associated with the given ID, or the default text if the ID is not found.
 */

export const createLabelByPimsTypeID = (pimsTypeID, settings) => {
  const pims = Object.values(PIMS_DETAILS).find(pims => pims.id === pimsTypeID);
  const pimsName = pims?.name || "";
  const defaultText = settings.default || "";
  return settings[pimsName] || defaultText;
};

// Functions: deployFieldPIMSPartnerID

/**
 * Determines whether to display a component based on the PIMS type ID.
 * If the ID is in the array, the component should not be displayed.
 *
 * @param {string} pimsTypeID - The PIMS type ID to check.
 * @returns {boolean} - Returns false if the ID is in the array (indicating the component should not be displayed), otherwise true.
 */
export const deployFieldPIMSPartnerID = (pimsTypeID) => {
  const ezyvetId = PIMS_DETAILS.ezyvet.id
  const shepherdId = PIMS_DETAILS.shepherd.id
  const digitailId = PIMS_DETAILS.digitail.id

  return ![ezyvetId, shepherdId, digitailId].includes(pimsTypeID);
};


// Functions: createPlaceholderToUrlBaseField

/**
 * Creates a the placeholder for the PIMS type ID.
 * If the ID is not found in the PIMS_DETAILS, it returns a default text.
 *
 * @param {string} pimsTypeID - The PIMS type ID to check.
 * @returns {string} - The URL structure associated with the given ID, or the default text if the ID is not found.
 */

export const createPlaceholderToUrlBaseField = (pimsTypeID) => {
  const pims = Object.values(PIMS_DETAILS).find(pims => pims.id === pimsTypeID);
  const urlStructure = pims?.urlBase?.structure || "";
  return (urlStructure) ? urlStructure : "PIMS Client BaseURL...";
};


// Function: validateURLById

/**
 * Validates the URL stored in `pimsClientBaseUrl` based on the pattern associated with the given `pimsTypeID`.
 *
 * This function looks up the URL pattern for a given `pimsTypeID` in the `url` and checks if the 
 * URL matches this pattern. It trims any whitespace from `url`
 * before performing the validation.
 * 
 * @param {string} pimsTypeID - The PIMS type ID to check.
 * @param {string} url - The url to check.
 * @returns {boolean} - Returns `true` if the `url` matches the pattern for the given `pimsTypeID`, otherwise `false`.
 */

export const validateURLById = (pimsTypeID, url) => {
  const pims = Object.values(PIMS_DETAILS).find(pims => pims.id === pimsTypeID);
  const urlPattern = pims?.urlBase?.pattern;

  if (!urlPattern) {
    return true;
  }

  const pattern = new RegExp(urlPattern);
  const isValid = pattern.test(url.trim());

  return isValid;
}



export const getClinicIDFromDigitail = (kontakServicesToken, digitailOauth2Token) => {
  const endpointUrl = "Digitail/auth/me";

  const requestOptions = {
    method: "GET",
    headers: new Headers({
      "Accept": "application/json",
      "Content-Type": "application/json",
      "Authorization": `Bearer ${kontakServicesToken}`,
      "digitailToken": digitailOauth2Token
    })
  };

  const errorMessage = 'Failed to extract Clinic ID. Please check if the Digitail OAuth2 token is valid.';

  return ajaxCaller(endpointUrl, requestOptions)
    .then(({ data }) => {
      const clinicId = data?.clinic?.id;

      if (!clinicId) {
        throw new Error();
      }

      return { isError: false, data: { clinicId }, message: "success" };
    })
    .catch(error => {
      console.log("Error:", error);
      return { isError: true, data: null, message: errorMessage };
    });
};

/**
 * Sorts two PIMS types by their name properties in lowercase.
 *
 * @param {Object} pimsTypeA - The first PIMS type object.
 * @param {Object} pimsTypeB - The second PIMS type object.
 * @param {string} [pimsTypeA.name] - The name of the first PIMS type.
 * @param {string} [pimsTypeB.name] - The name of the second PIMS type.
 * @returns {number} -1 if pimsTypeA should come before pimsTypeB, 1 if pimsTypeA should come after pimsTypeB, 0 if they are equal.
 */
export const sortPimsTypeByLowercaseName = (pimsTypeA, pimsTypeB) => {
  const nameA = (typeof pimsTypeA?.name === 'string' ? pimsTypeA.name.toLowerCase() : '');
  const nameB = (typeof pimsTypeB?.name === 'string' ? pimsTypeB.name.toLowerCase() : '');

  if (nameA < nameB) {
    return -1;
  }

  if (nameA > nameB) {
    return 1;
  }

  return 0;
}

export const isValidBaseUrl = (url) => {
  const pattern = new RegExp("^(http|https)://");
  return pattern.test(url);
}

export const appendPimsPartnerIDToUrl = (url, pimsPartnerID) => {
  if (!url || !pimsPartnerID) {
    return url;
  };

  const urlWithoutAlphanumericInTheEnd = url.replace(/\/([a-zA-Z0-9]+)\/?$/, "");


  if (urlWithoutAlphanumericInTheEnd.endsWith("/")) {
    return `${urlWithoutAlphanumericInTheEnd}${pimsPartnerID}`;
  };

  return `${urlWithoutAlphanumericInTheEnd}/${pimsPartnerID}`;
}

export const handleFileUpload = (
  event,
  uploadFunction,
  setErrorMessage,
  clearError,
  validExtensions = IMAGE_VALID_EXTENSIONS,
  maxSizeBytes = IMAGE_MAX_SIZE_BYTES,
  maxHeight = IMAGE_MAX_HEIGHT,
  maxWidth = IMAGE_MAX_WIDTH
) => {
  clearError();  // Clear any existing error

  const fileInput = event.target;
  const file = fileInput.files?.[0];

  if (!file) return;

  // Validate file size
  if (!validateFileSize(file, maxSizeBytes, setErrorMessage)) {
    fileInput.value = ""; // Reset input
    return;
  }

  // Validate file extension
  if (!validateFileExtension(file, validExtensions, setErrorMessage)) {
    fileInput.value = ""; // Reset input
    return;
  }

  // Handle TIFF files separately
  const fileExtension = file.name.split('.').pop()?.toLowerCase();
  if (["tiff", "tif"].includes(fileExtension)) {
    handleTiffFile(file, maxWidth, maxHeight, setErrorMessage, uploadFunction, fileInput);
  } else {
    // Validate image dimensions for non-TIFF files
    validateImageDimensions(file, maxWidth, maxHeight, setErrorMessage, uploadFunction, fileInput);
  }
};

export const validateFileExtension = (file, validExtensions, setErrorMessage) => {
  const fileExtension = file.name.split('.').pop()?.toLowerCase();
  if (!fileExtension || !validExtensions.includes(fileExtension)) {
    setErrorMessage(`Unsupported format!\nMust be one of:\n ${IMAGE_ACCEPT_EXTENSIONS}`);
    return false;
  }
  return true;
};

export const handleTiffFile = async (file, maxWidth, maxHeight, setErrorMessage, uploadFunction, fileInput) => {
  const reader = new FileReader();

  reader.onload = async function (e) {
    try {

      // Decode the TIFF image
      const tiffImages = await decode(new Uint8Array(e.target.result));
      const image = tiffImages[0]; // Access the first image (for handling the multi-page TIFFs)
      const { width, height } = image;

      // Check if the TIFF image exceeds the allowed dimensions
      if (width > maxWidth || height > maxHeight) {
        setErrorMessage(`Max dimensions are:\n ${maxHeight} height x ${maxWidth} width.`);
        fileInput.value = "";
      } else {
        uploadFunction(file);
      }
    } catch (error) {

      setErrorMessage("Error reading TIFF file.");
      fileInput.value = "";
    }
  };
  // Read the file as an ArrayBuffer
  reader.readAsArrayBuffer(file);
};

export const validateFileSize = (file, maxSizeBytes, setErrorMessage) => {
  if (file.size > maxSizeBytes) {
    setErrorMessage(`Maximum file size is ${maxSizeBytes / 1000000}MB.`);
    return false;
  }
  return true;
};

export const validateImageDimensions = (file, maxWidth, maxHeight, setErrorMessage, uploadFunction, fileInput) => {
  const _URL = window.URL || window.webkitURL;
  const image = new Image();
  const objectUrl = _URL.createObjectURL(file);

  image.onload = function () {
    if ((image.width > maxWidth || image.height > maxHeight) && file.name.split('.').pop().toLowerCase() !== 'svg') {
      setErrorMessage(`Max dimensions are:\n ${maxHeight} height x ${maxWidth} width.`);
      fileInput.value = "";
    } else {
      uploadFunction(file);
    }
    _URL.revokeObjectURL(objectUrl);
  };

  image.src = objectUrl;
};

export const logoutUtility = () => {
  // Remove all relevant cookies
  Cookies.remove('access_token');
  Cookies.remove('logged_in_date');
  Cookies.remove('role_id');
  Cookies.remove('user_id');
  Cookies.remove('secret_key');

  // Redirect the user to the login page
  window.location.href = '/pages/authentication';
};

export const saveToCookies = (user) => {
  Cookies.set("kontak_admin", user.kontakAdmin);
  Cookies.set("access_token", user.token);
  Cookies.set("logged_in_date", user.loggedInDate);
  Cookies.set("user_id", user.id);
  Cookies.set("privileges", JSON.stringify(user.privileges));
  Cookies.set("role_id", user.roleId);
  Cookies.set("user_name", user.name);
  Cookies.set("user_email", user.email);
  Cookies.set("user_avatar", user.avatar);
  Cookies.set("isEnableAppDirect", user.isEnableAppDirect);
  Cookies.set("secret_key", user.secretKey);
}