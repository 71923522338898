/* eslint-disable no-useless-concat */
import React, { useState, useEffect } from "react";
import "../style.css";
import Cookies from "js-cookie";
import { ajaxCaller, ajaxNonJsonCaller } from "../ajaxCaller";
import { Modal } from "react-bootstrap";
import 'react-select2-wrapper/css/select2.css';
import $ from 'jquery'
import { afterLogin } from "../actions/users"
import CreatableSelect from 'react-select/creatable';
import {saveToCookies } from "./helpers/utils";

const Authentication = () => {
  // const systemIsTempDown = false;
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [authenticated, setAuthenticated] = useState(null);
  const [authenticationFailed, setAuthenticationFailed] = useState(null);
  const [authenticatedUser, setAuthenticatedUser] = useState(null);
  const [companies, setCompanies] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const [passwordRecoveryEmail, setPasswordRecoveryEmail] = useState(null);
  const [showCompanySelection, setShowCompanySelection] = useState(false);

  const [company, setCompany] = useState(null);

  const authenticateAdmin = (event) => {
    ajaxNonJsonCaller("User/SetCompanyForKontaAdmin?companyId=" + companyId, {
      method: "post",
      headers: new Headers({
        Authorization: "Bearer " + authenticatedUser.token,
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
    }).then((response) => {
      if (!response.isError) {
        getCompany(authenticatedUser.token, () => {
          saveToCookies(authenticatedUser);
          if (!afterLogin(response)) {
            setAuthenticated(true);
          }
          setShowCompanySelection(false);
        });

      }
    });
  }

  const getCompany = (token, callback) => {
    ajaxCaller("Company/GetCurrentUserCompany/", {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
    }).then((response) => {
      if (!response.isError) {
        Cookies.set("company_logo", response.logo);
        Cookies.set("companyKey", response.companyKey);
        Cookies.set("company_id", response.id);
        callback && callback();
      }
    });
  }

  const authenticateUser = () => {
    // setAuthenticationFailed(false);

    // if (systemIsTempDown && password !== "PaSSSuper12rt^3") {
    //   setAuthenticationFailed(true);
    //   return;
    // }

    ajaxCaller("User/Authenticate", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        password: password,
      }),
    }).then((response) => {
      if (response.isError) {
        setAuthenticationFailed(true);
      } else {
        if (response.kontakAdmin) {
          setAuthenticatedUser(response);
          ajaxCaller("Company/GetAllCompaniesRet", {
            method: "get",
            headers: new Headers({
              Authorization: "Bearer " + response.token,
              "Content-Type": "application/json",
              Accept: "application/json",
            }),
          }).then((companies) => {
            setCompanies(companies);
            setShowCompanySelection(true);
          });
        } else {
          getCompany(response.token, () => {
            saveToCookies(response);
            if (!afterLogin(response)) {
              setAuthenticated(true);
            }
          });
        }
      }
    });
  }

  // function onCompanySelected(event) {
  //   const companyId = $(event.target).val();
  //   setCompanyId(companyId);
  // }

  function onCompanySelected(event) {
    const company = event;
    setCompany(company);
    setCompanyId(company?.value);
  }



  useEffect(() => {
    if (authenticated) {
      window.location.href = "/dashboard";
    }
  });

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const [errorMessage, setErrorMessage] = useState(null);
  const recoveryPassword = () => {
    ajaxCaller("User/PasswordRecovery/" + passwordRecoveryEmail, {
      method: "put",
      headers: {
        "Content-Type": "application/json"
      }
    }).then(response => {
      if (response.isError) {
        setErrorMessage(response.message);
      } else {
        $('#modalForgotPass').modal('toggle');
        setEmail(null);
        setErrorMessage(null);
      }
    });
  }

  const isPasswordRecoveryDisabled = (email) => {
    if (typeof email !== 'string' && email !== null) {
      return true;
    }
    return email === null || email.trim() === "" || !validateEmail(email);
  };

  return (
    <div>
      <div className="modal fade" id="modalForgotPass" tabIndex="-1" role="dialog" aria-labelledby="modalForgotPass" aria-hidden="true">
        <div className="modal-dialog" role="document">
          {errorMessage && (<div className="alert alert-danger mt-4" role="alert">{errorMessage}</div>)}
          <div className="modal-content">
            <div className="modal-body">
              <div className="container-fluid">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close"></button>
                <div className="row justify-content-center">
                  <div className="col-12 text-center mt-4">
                    <h5 className="mb-4">
                      Please provide your email
                    </h5>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <input type="email" onChange={(event) => setPasswordRecoveryEmail(event.target.value)} name="email-rec" id="email-rec" className="form-style small" placeholder="Your Email..." autoComplete="off" />
                    </div>
                  </div>
                  <div className="col-12 text-center mt-3">
                    <button onClick={() => recoveryPassword()} disabled={isPasswordRecoveryDisabled(passwordRecoveryEmail)} type="button" className="btn btn-blue btn-width-170 border-4">Recovery Password</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="section full-height img-background-cover"
        style={{ backgroundImage: `url(${require("../img/log-in.jpg")})` }}
      >
        <div className="container z-bigger-10">
          <div className="row full-height justify-content-center">
            <div className="col-md-8 col-lg-6 col-xl-5 align-self-center">
              <div className="section log-in-section px-3 px-lg-4 py-4">
                <div className="row justify-content-center py-2 py-lg-3">
                  <div className="col-auto">
                    <img src={require("../img/logo.png")} alt="logo" style={{ width: '82%', height: 'auto', paddingLeft:'8%' }}/>
                  </div>
                  <div className="col-10 pt-4">
                    <h5 className="text-center mb-3 pb-1">
                      Please provide your email and password
                    </h5>
                  </div>
                  {authenticationFailed && (<div className="alert alert-danger" role="alert">Check your email and password</div>)}
                  <div className="col-10 mb-2">
                    <div className="form-group">
                      <label className="mb-1" htmlFor="email">
                        Your Email
                      </label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        onKeyPress={(event) => {
                          var key = event.which;
                          if (key === 13 || key === 14) {
                            authenticateUser()
                          }
                        }}
                        onChange={(event) => setEmail(event.target.value)}

                        className="form-style"
                        placeholder="Your Email..."
                        autoComplete="off"
                      />
                    </div>
                  </div>
                  <div className="col-10 mb-2">
                    <div className="form-group">
                      <label className="mb-1" htmlFor="password">
                        Your Password
                      </label>
                      <input
                        type="password"
                        name="password"
                        id="password"
                        className="form-style"
                        onKeyPress={(event) => {
                          var key = event.which;
                          if (key === 13 || key === 14) {
                            authenticateUser()
                          }
                        }}
                        onChange={(event) => setPassword(event.target.value)}
                        placeholder="Your Password..."
                        autoComplete="off"
                      />
                    </div>
                  </div>
                  <div className="col-10 mb-2 text-center">
                    <p className="mb-0">
                      <a
                        href="/#"
                        className="font-weight-500"
                        data-toggle="modal"
                        data-target="#modalForgotPass"
                      >
                        Forgot password?
                      </a>
                    </p>
                  </div>
                  <div className="col-10 text-center">
                    <button
                      type="button"
                      data-toggle="modal"
                      data-target="#modalSelectCompany"
                      className="btn btn-blue btn-width-170 border-4"
                      onClick={authenticateUser}
                    >
                      Login
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={showCompanySelection}
        onHide={() => setShowCompanySelection(false)}
      >
        <div className="modal-body">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-12 text-center">
                <h5 className="mb-4">Please select a company</h5>
              </div> 
              <div className="col-12">
                <div className="section z-bigger-20">
                  <CreatableSelect
                    className="input-select-style no-search bigger-text"                   
                    isClearable={true}
                    isDisabled={false}
                    isLoading={false}
                    isCreatable={false}
                    onChange={(event) => onCompanySelected(event)}          
                    value={company}
                    defaultValue={company}
                    placeholder="Company..."
                    theme='bootstrap4'
                    options= {companies && companies.map((x) => ({
                      value: x.id, label: x.name + 
                      (Boolean(x.hasCalls) === true ?  " " + "\u{1f4f1}" : "") + 
                      (Boolean(x.hasLiveChats) === true ? " " + "\u{2328}" : "") +  
                      (Boolean(x.isUncategorized) === true ? " " + "\u{2753}": "")
                    }))}
                    formatCreateLabel={() => undefined}
                    isValidNewOption={() => false}
                  />

                  {/* <Select2
                    className="input-select-style no-search bigger-text"
                    data={
                      companies &&
                      companies.map((x) => ({ id: x.id, text: x.name }))
                    }
                    defaultValue={companyId}
                    options={{
                      placeholder: 'Company',
                      theme: 'bootstrap4',
                      minimumResultsForSearch: -1
                    }}
                    onChange={(event) => onCompanySelected(event)}
                  /> */}
                </div>
              </div>
              <div className="col-12 text-center mt-3">
                <button
                  className="btn btn-blue btn-width-170 border-4"
                  onClick={authenticateAdmin}
                >
                  Login
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Authentication;
