import React, { useEffect } from "react";
import $ from "jquery";
import { FaExclamationCircle } from 'react-icons/fa';

const ErrorModal = (props) => {
  const setAndShowError = () => {
    if (props.errorMessage && props.errorMessage !== "" && props.errorMessage != null) {
      $('#modalError').modal('show');
    }
  };

  useEffect(() => {
    return () => {
      $('#modalError').modal('hide');
    };
  }, [props.errorMessage]);

  useEffect(
    setAndShowError
  , [props.errorMessage]);
  
  return (
    <>
      <div
        className="modal fade"
        id="modalError"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="modalErrorTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-error" role="document">
          <div className="modal-content modal-content-error">
            <div className="modal-header modal-header-error">
              <h6 className="modal-title font-weight-bold-error" id="modalErrorTitle">
                {props.errorTitle || "Something went wrong"}
              </h6>
            </div>
            <div className="modal-body modal-body-error">
              {props.errorMessage && (
                <div className="alert alert-danger" role="alert" style={{ whiteSpace: "pre-line" }}>
                  <FaExclamationCircle style={{ marginRight: '8px', fontSize: '1.2rem', color: '#D21238' }} />
                  {props.errorMessage}
                </div>
              )}
            </div>
            <div className="modal-footer modal-footer-error">
              <button
                type="button"
                data-dismiss="modal"
                aria-label="Close"
                className="btn btn-secondary"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ErrorModal;