import React, { useEffect } from "react";
import "./ChangePasswordModal.css";
import { FaExclamationCircle } from "react-icons/fa";

const ChangePasswordModal = ({
    isModalOpen,
    featureFlags,
    oldPassword,
    newPassword,
    newPasswordConfirm,
    logMeOut,
    modalErrorMessageTitle,
    modalErrorMessage,
    modalMessage,
    setOldPassword,
    setNewPassword,
    setNewPasswordConfirm,
    setLogMeOut,
    resetFields,
    changePassword,
    setIsModalOpen,
}) => {


    useEffect(() => {
        if (
            oldPassword &&
            newPassword &&
            newPasswordConfirm &&
            !isModalOpen
        ) {
            setIsModalOpen(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [oldPassword, newPassword, newPasswordConfirm]);

    if (!isModalOpen) return null;

    const isUpdateDisabled =
        newPassword !== newPasswordConfirm ||
        newPassword === "" ||
        newPassword.trim() === "";

    // Extracted components
    const ErrorMessages = ({ title, message }) => (
        <>
            <div className="change-password-messages">
                <span className="change-password-error">{title}</span>
            </div>
            {message && (
                <div
                    className="alert alert-danger alert-error-modal"
                    role="alert"
                    style={{ whiteSpace: "pre-line" }}
                >
                    <FaExclamationCircle
                        style={{
                            marginRight: "8px",
                            fontSize: "1.2rem",
                            color: "#D21238",
                        }}
                    />
                    {message}
                </div>
            )}
        </>
    );

    const WarningMessages = ({ title, message }) => (
        <>
            <div className="change-password-messages">
                <span className="change-password-warning">{title}</span>
            </div>
            {message && (
                <div
                    className="alert alert-danger alert-warning-modal"
                    role="alert"
                    style={{ whiteSpace: "pre-line" }}
                >
                    <FaExclamationCircle
                        style={{
                            marginRight: "8px",
                            fontSize: "1.2rem",
                            color: "#d99d4f",
                        }}
                    />
                    {message}
                </div>
            )}
        </>
    );

    const SuccessMessage = ({ message }) => (
        <div className="change-password-messages">
            <span className="change-password-success">{message}</span>
        </div>
    );

    return (
        <div className="modal-overlay">
            <div className="modal-container">
                <header className="modal-header modal-header-change-password">
                    <h2>Change Password</h2>
                    <button
                        className="close-button"
                        onClick={() => {
                            setIsModalOpen(false);
                            resetFields();
                        }}
                        aria-label="Close"
                    >
                        &times;
                    </button>
                </header>
                <form className="change-password-form">
                    {/* Old Password Field */}
                    <div className="form-group section height-40-style light-style">
                        <label>
                            Current Password <span className="required"> *</span>
                        </label>
                        <input
                            type="password"
                            className="password-input"
                            value={oldPassword}
                            onChange={(e) => setOldPassword(e.target.value)}
                            placeholder="Current Password"
                        />
                    </div>

                    {/* New Password Field */}
                    <div className="form-group section height-40-style light-style">
                        <label>
                            New Password <span className="required"> *</span>
                        </label>
                        <input
                            type="password"
                            className="password-input"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            placeholder="New Password"
                        />
                    </div>

                    {/* Confirm New Password Field */}
                    <div className="form-group section height-40-style light-style">
                        <label>
                            Confirm New Password <span className="required"> *</span>
                        </label>
                        <input
                            type="password"
                            className="password-input"
                            value={newPasswordConfirm}
                            onChange={(e) => setNewPasswordConfirm(e.target.value)}
                            placeholder="Confirm Password"
                        />
                    </div>

                    {/*Messages */}
                    <>
                        {modalErrorMessageTitle ? (
                            <ErrorMessages
                                title={modalErrorMessageTitle}
                                message={modalErrorMessage}
                            />
                        ) : (
                            modalMessage && <SuccessMessage message={modalMessage} />
                        )}
                    </>

                    <>
                        {(isUpdateDisabled && newPasswordConfirm) &&
                            <WarningMessages
                                title="Passwords Do Not Match!"
                            />
                        }
                    </>

                    {/* Log Me Out Check Box */}
                    {featureFlags && (
                        <div className="col-12 mt-0 pt-0 pl-0 pr-0">
                            <div className="form-group">
                                <div class="checkbox-container">
                                    <input
                                        type="checkbox"
                                        id="logmeoutchk"
                                        className="styled-checkbox"
                                        name="logmeoutchk-edit-x"
                                        checked={logMeOut}
                                        onChange={() => setLogMeOut(!logMeOut)}
                                    />
                                    <label
                                        className="checkbox checkbox-white"
                                        htmlFor="logmeoutchk"
                                    >
                                        Sign out from all other accounts and devices.
                                    </label>
                                </div>
                            </div>
                        </div>
                    )}

                    {/* Actions */}
                    <div className="form-actions">
                        <button
                            type="button"
                            className="btn btn-white btn-width-80 btn-height-36 border-5 font-size-14 mr-1 mt-2 mt-xl-0"
                            onClick={() => {
                                setIsModalOpen(false);
                                resetFields();
                            }}
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            className="btn btn-blue-gray btn-width-80 btn-height-36 border-4 font-size-14 mr-1 mt-2 mt-xl-0"
                            onClick={() => changePassword()}
                            disabled={isUpdateDisabled}
                        >
                            Update
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ChangePasswordModal;
