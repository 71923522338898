export const DIGITAIL_PIMS_TYPE_ID = 8;
export const SHEPHERD_PIMS_TYPE_ID = 11;
export const SHEPHERD_CLIENT_BASE_URL = 'https://app.shepherd.vet';

export const PIMS_DETAILS = {
    rhapsody: {
        id: "1",
        name: "rhapsody",
        urlBase: {
            structureUrl: "",
            pattern: undefined
        }
    },
    vetspire: {
        id: "2",
        name: "vetspire",
        urlBase: {
            structureUrl: "",
            pattern: undefined
        }
    },
    avimark: {
        id: "3",
        name: "avimark",
        urlBase: {
            structureUrl: "",
            pattern: undefined
        }
    },
    cornerstone: {
        id: "4",
        name: "cornerstone",
        urlBase: {
            structureUrl: "",
            pattern: undefined
        }
    },
    evet: {
        id: "5",
        name: "evet",
        urlBase: {
            structureUrl: "",
            pattern: undefined
        }
    },
    vetter: {
        id: "6",
        name: "vetter",
        urlBase: {
            structureUrl: "",
            pattern: undefined
        }
    },
    impromed: {
        id: "7",
        name: "impromed",
        urlBase: {
            structureUrl: "",
            pattern: undefined
        }
    },
    digitail: {
        id: "8",
        name: "digitail",
        urlBase: {
            structureUrl: "https://vet.digitail.io",
            pattern: undefined
        }
    },
    provet: {
        id: "10",
        name: "provet",
        urlBase: {
            structureUrl: "",
            pattern: undefined
        }
    },
    shepherd: {
        id: "11",
        name: "shepherd",
        urlBase: {
            structureUrl: "",
            pattern: undefined
        }
    },
    ezyvet: {
        id: "12",
        name: "ezyvet",
        urlBase: {
            structure: "https://{name}.us{##}.ezyvet.com",
            pattern: "^https://\\w+\\.us\\w{2}\\.ezyvet\\.com$",
        }
    },
    cornerstone_local: {
        id: "13",
        name: "cornerstone_local",
        urlBase: {
            structureUrl: "",
            pattern: undefined
        }
    },
    avimark_local: {
        id: "14",
        name: "avimark_local",
        urlBase: {
            structureUrl: "",
            pattern: undefined
        }
    },
    impromed_local: {
        id: "16",
        name: "impromed_local",
        urlBase: {
            structureUrl: "",
            pattern: undefined
        }
    },
    evet_local: {
        id: "17",
        name: "evet_local",
        urlBase: {
            structureUrl: "",
            pattern: undefined
        }
    },
    vetter_local: {
        id: "18",
        name: "vetter_local",
        urlBase: {
            structureUrl: "",
            pattern: undefined
        }
    },
    petdesk: {
        id: "19",
        name: "petdesk",
        urlBase: {
            structureUrl: "",
            pattern: undefined
        }
    }
};

// Define default values for Image upload validation
export const IMAGE_VALID_EXTENSIONS = ['jpg', 'jpeg', 'png', 'tiff', 'tif', 'svg'];
export const IMAGE_ACCEPT_EXTENSIONS = IMAGE_VALID_EXTENSIONS.map(ext => `.${ext}`).join(', ');;
export const IMAGE_MAX_SIZE_BYTES = 1000000;
export const IMAGE_MAX_HEIGHT = 500;
export const IMAGE_MAX_WIDTH = 500;      

export const CREDENTIAL_SUPPORTED_PIMS_IDS = [
    PIMS_DETAILS.ezyvet.id,
    PIMS_DETAILS.digitail.id,
    PIMS_DETAILS.shepherd.id,
    PIMS_DETAILS.provet.id
  ];