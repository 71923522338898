import React, { useState, useEffect } from "react";
import { ajaxCaller } from "../ajaxCaller";
import {
  getAccessToken,
  isKontakAdmin,
  getCurrentUserId,
} from "../authentification";
import Select2 from "react-select2-wrapper";
import FacilitiesPicker from './components/facilitiesPicker';
import SettingsPageNavBar from "./components/settingsPageNavBar";
import $ from "jquery";
import ErrorModal from "./components/errorModal";
import EditUserModal from "./components/EditUserModal/EditUserModal";

const Team = (props) => {
  const [roles, setRoles] = useState(null);
  const [isKontakAdminHere] = useState(isKontakAdmin())
  const [errorMessage, setErrorMessage] = useState(null);
  const clearError = () => {
    setErrorMessage("");
  };
  const getRoles = () => {
    clearError();
    ajaxCaller("Roles/GetRoles", {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + getAccessToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
    }).then((response) => {
      if (!response.isError) {
        if (isKontakAdminHere) {
          setRoles(response);
        }
        else {
          setRoles(
            response.filter(
              (role) => role.id !== 1
            )
          );
        }
      }
      clearRoleFields();
    });
  };

  useEffect(getRoles, []);

  const [users, setUsers] = useState(null);
  const getUsers = () => {
    clearError();
    ajaxCaller("User/GetUsers", {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + getAccessToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
    }).then((response) => {
      if (!response.isError) {
        if (isKontakAdminHere) {
          setUsers(
            response.users.filter(
              (user) => user.id !== getCurrentUserId()
            )
          );
        }
        else {
          setUsers(
            response.users.filter(
              (user) => user.roleId !== 1 && user.id !== getCurrentUserId()
            )
          );
        }
      }
    });
  };

  useEffect(getUsers, []);

  const getRole = (roleId) => {
    var role =
      roles && roles.find((x) => x.id.toString() === roleId.toString());

    if (role) {
      return role.name;
    }
  };

  const [origGroupIds, setOrigGroupIds] = useState(null);
  const [groupIds, setGroupIds] = useState(null);
  const facilitiesChanged = (regionFacilities) => {
    var newGroupIds = [];
    regionFacilities.forEach(region => {
      region.groups.forEach(group => {

        if (group.checked) {
          newGroupIds.push(group.id);
        }
      })
    });
    setGroupIds(newGroupIds);
  }


  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [selectedRoleId, setSelectedRoleId] = useState(null);

  const removeRole = (roleId) => {
    clearError();
    ajaxCaller("Roles/RemoveRole/" + roleId, {
      method: "delete",
      headers: new Headers({
        Authorization: "Bearer " + getAccessToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
    }).then((response) => {
      if (!response.isError) {
        setRoles((prev) => [...prev].filter((a) => a.id !== roleId));
      }
      else {
        setErrorMessage(response.message)
      }
    });
  };

  const [privileges, setPrivileges] = useState([]);
  const getPrivileges = () => {
    clearError();
    ajaxCaller("Privilege/GetPrivileges", {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + getAccessToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
    }).then((privileges) => {
      setPrivileges(
        privileges//.filter((a) => a.name !== "ViewEditFacilityAndRegions")
      );
    });
  };

  useEffect(getPrivileges, []);

  const [assignedPrivileges, setAssignedPrivileges] = useState([]);
  const getAssignPrivileges = (roleId) => {
    clearError();
    ajaxCaller("Roles/GetPrivilegesByRole/" + roleId, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + getAccessToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
    }).then((response) => {
      if (!response.isError) {
        setAssignedPrivileges(response);
      }
    });
  };



  const privilegeClicked = (privilegeId, checked) => {
    let privs = assignedPrivileges && assignedPrivileges.map((a) => a);
    if (!privs) {
      return;
    }
    if (checked) {
      if (!privs.some((a) => a.id === privilegeId)) {
        privs = privs.concat({ id: privilegeId, name: privilegeId });
      }
    } else {
      privs = privs.filter((a) => a.id !== privilegeId);
    }

    setAssignedPrivileges(privs);
  };

  const savePrivileges = (roleId) => {
    clearError();
    ajaxCaller("Roles/AssignPrivilegesToRole", {
      method: "put",
      headers: new Headers({
        Authorization: "Bearer " + getAccessToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
      body: JSON.stringify({
        privilegeIds: assignedPrivileges.map((a) => a.id),
        roleId: roleId,
      }),
    }).then((response) => {
      if (!response.isError) {
      } else {
        setErrorMessage(response.message)
      }
      clearRoleFields();
    });
  };

  const [roleName, setRoleName] = useState("");
  const clearRoleFields = () => {
    clearError();
    setAssignedPrivileges([]);
    setRoleName("");
  }
  const createRole = () => {
    clearRoleFields();
    ajaxCaller("Roles/AddRole", {
      method: "post",
      headers: new Headers({
        Authorization: "Bearer " + getAccessToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
      body: JSON.stringify({
        name: roleName,
        privilegeIds: assignedPrivileges && assignedPrivileges.map((x) => x.id),
      }),
    }).then((response) => {
      if (!response.isError) {
        getRoles();
      }
      else {
        setErrorMessage(response.message)
      }
    });
  };

  const [editUserEmail, setEditUserEmail] = useState("");
  const [editUserName, setEditUserName] = useState("");
  const [editUserRoleId, setEditUserRoleId] = useState("");
  const [editUserId, setEditUserId] = useState("");
  const [displayInvitationSentLabel, setDisplayInvitationSentLabel] = useState(false);
  const [origUserName, setOrigUserName] = useState("");
  const [origUserRoleId, setOrigUserRoleId] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);

  const updateUser = () => {
    setIsModalOpen(false);
    clearError();
    ajaxCaller("User/EditUser", {
      method: "put",
      headers: new Headers({
        Authorization: "Bearer " + getAccessToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
      body: JSON.stringify({
        id: editUserId,
        name: editUserName,
        email: editUserEmail,
        roleId: editUserRoleId,
        groupIds: groupIds
      }),
    }).then((response) => {
      if (!response.isError) {
        getUsers();
      }
      else {
        setErrorMessage(response.message)
      }
    });
  };

  const clearUserFields = () => {
    clearError();
    setSelectedRoleId(null);
    setUserName("");
    setUserEmail("");
  }

  const inviteUser = (forService, userEmail, userRoleId, userName, userId) => {
    clearError();
    ajaxCaller(`User/InviteUser?ForService=${forService}`, {
      method: "post",
      headers: new Headers({
        Authorization: "Bearer " + getAccessToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
      body: JSON.stringify({
        id: userId,
        email: userEmail,
        roleId: userRoleId,
        name: userName,
        groupIds: groupIds
      }),
    }).then((response) => {
      if (!response.isError) {
        getUsers();
        clearUserFields();
      } else {
        setErrorMessage(response.message);
      }
    });
  };

  const inviteUserById = (userId, userEmail) => {

    ajaxCaller('User/InviteUserByEmail', {     
      method: "post",
      headers: new Headers({
        Authorization: "Bearer " + getAccessToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
      body: JSON.stringify({
        id: userId,
        email: userEmail
      }),
    }).then((response) => {
      if (!response.isError) {
        setDisplayInvitationSentLabel(true);
        function hideInvitationSentLabel() {
          setDisplayInvitationSentLabel(false);
        }
        setTimeout(hideInvitationSentLabel, 5000);
      }
    });
  };

  const cantSendInvite = () => {
    return selectedRoleId == null || userEmail === "" || userEmail === " " || userName === "" || userName === " " || userName == null || userEmail == null;
  }

  const removeUser = (userId) => {
    ajaxCaller(`User/Delete/${userId}`, {
      method: "delete",
      headers: new Headers({
        Authorization: "Bearer " + getAccessToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
    }).then((response) => {
      if (!response.isError) {
        getUsers();
      }
    });
  };

  const extractPrivileges = (roleId) => {

    var role = roles && roles.find(x => x.id === roleId);

    return role && role.privileges;
  }

  useEffect(() => {
    $('[data-toggle="tooltip"]').tooltip();
    $('[data-toggle-second="tooltip"]').tooltip();
  });


  const handleEditButtonClick = (user) => {
    setEditUserEmail(user.email);
    setEditUserName(user.name);
    setOrigUserName(user.name);
    setEditUserRoleId(user.roleId);
    setOrigUserRoleId(user.roleId);
    setEditUserId(user.id);

     setTimeout(() => {
    setIsModalOpen(true);
  }, 10);
  };

  return (
    <>
      <>
        <div
          className="modal fade"
          id="modalCreateRole"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="modalCreateRole"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div style={{ width: "600px", maxWidth: "600px" }} className="modal-content">
              <div className="modal-body py-3">
                <div className="container-fluid">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  ></button>
                  <div className="row">
                    <div className="col-12">
                      <h5 className="mb-4 font-weight-700">Create a New Role</h5>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4 align-self-center">
                      <p className="mb-2 mb-sm-0 size-14 font-weight-700">
                        Role Name
                      </p>
                    </div>
                    <div className="col-sm-8 pl-sm-0">
                      <div className="form-group">
                        <input
                          type="text"
                          value={roleName}
                          onChange={(event) => setRoleName(event.target.value)}
                          className="form-style border-0 small-40"
                          placeholder="Enter role name..."
                          autoComplete="off"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-sm-4">
                      <p className="mb-2 mb-sm-0 size-14 font-weight-700">
                        Privileges
                      </p>
                    </div>
                    <div className="col-sm-8 pl-sm-0">
                      <div className="section">
                        {privileges &&
                          privileges.map((privilege) => (
                            <div key={privilege.id} className="form-group mt-1">
                              <input
                                type="checkbox"
                                onChange={(event) =>
                                  privilegeClicked(
                                    privilege.id,
                                    event.target.checked
                                  )
                                }
                                id={"privileges_" + privilege.id}
                                checked={
                                  assignedPrivileges &&
                                  assignedPrivileges.some(
                                    (a) => a.id === privilege.id
                                  )
                                }
                              />
                              <label
                                className="checkbox checkbox-gray mb-0"
                                htmlFor={"privileges_" + privilege.id}
                              >
                                {privilege.notes}
                              </label>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 text-center mt-4">
                      <button
                        type="button"
                        onClick={() => setAssignedPrivileges([])}
                        data-dismiss="modal"
                        aria-label="Close"
                        className="btn btn-grey btn-width-130 btn-height-40 border-2 font-size-14 mr-1"
                      >
                        CANCEL
                      </button>
                      <button
                        type="button"
                        data-dismiss="modal"
                        disabled={roleName === "" || roleName === " " || roleName == null}
                        onClick={() => createRole()}
                        className="btn btn-blue btn-width-130 btn-height-40 border-2 font-size-14 ml-1"
                      >
                        SUBMIT
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>

      <ErrorModal errorMessage={errorMessage} clearError={clearError} />
      <SettingsPageNavBar pageName="team" />
      <div className="section sec-move-left">
        <div className="section py-5 px-lg-2">
          <div className="section px-1">
            <div className="container-fluid z-bigger-10">
              <div className="row mt-3 mt-lg-4">
                <div className="col-12">
                  <h5 className="mb-2 pb-1">Team</h5>
                </div>
                <div className="col-12">
                  <div className="section background-white p-3 p-lg-4">
                    <div className="row">
                      <div className="col-12">
                        <h6 className="mb-3">Invite a team member</h6>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3 col-xl-2 align-self-center pr-sm-0">
                        <p className="mb-2 mb-md-0 font-weight-500">
                          Email address
                        </p>
                      </div>
                      <div className="col-md-4 col-xl-3 align-self-center">
                        <div className="form-group">
                          <input
                            type="email"
                            value={userEmail}
                            onChange={(event) =>
                              setUserEmail(event.target.value)
                            }
                            className="form-style border-0 small-40"
                            placeholder="Enter an email address here..."
                            autoComplete="off"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-3 col-xl-2 align-self-center pr-sm-0">
                        <p className="mb-2 mb-md-0 font-weight-500">Full Name</p>
                      </div>
                      <div className="col-md-4 col-xl-3 align-self-center">
                        <div className="form-group">
                          <input
                            type="email"
                            value={userName}
                            onChange={(event) =>
                              setUserName(event.target.value)
                            }
                            className="form-style border-0 small-40"
                            placeholder="Enter a user name here..."
                            autoComplete="off"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-3 col-xl-2 align-self-center">
                        <p className="mb-2 mb-md-0 font-weight-500">
                          Assign to Location(s)
                        </p>
                      </div>

                      <FacilitiesPicker subClassName={"select-location full background-blue-light"} className={"col-md-4 col-xl-3 align-self-center"} onChange={facilitiesChanged}></FacilitiesPicker>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-3 col-xl-2 align-self-center pr-sm-0">
                        <p className="mb-2 mb-md-0 font-weight-500">Role</p>
                      </div>
                      <div className="col-md-4 col-xl-3 align-self-center">
                        <div className="section z-bigger-20 height-40-style light-style">
                          {roles && (
                            <Select2
                              className="input-select-style no-search bigger-text"
                              data={
                                roles &&
                                roles.map((x) => ({ id: x.id, text: x.name }))
                              }
                              defaultValue={selectedRoleId}
                              options={{
                                placeholder: "Select a role",
                                theme: "bootstrap4",
                                minimumResultsForSearch: -1,
                              }}
                              onChange={(event) =>
                                setSelectedRoleId(event.target.value)
                              }
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-12">
                        <button
                          disabled={cantSendInvite()}
                          onClick={() => inviteUser("chat", userEmail, selectedRoleId, userName)}
                          className="btn btn-blue btn-width-130 btn-height-40 border-0 font-size-16"
                        >
                          Send Chat Invite
                        </button>
                        &nbsp; or &nbsp;
                        <button
                          disabled={cantSendInvite()}
                          onClick={() => inviteUser("kontakRetriever", userEmail, selectedRoleId, userName)}
                          className="btn btn-blue btn-width-130 btn-height-40 border-0 font-size-16"
                        >
                          Send PetDesk Phones Invite
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 pt-2 mt-1">
                  <div className="section background-white p-3 p-lg-4">
                    <div className="row">
                      <div className="col-12">
                        <h6 className="mb-3">Roles</h6>
                      </div>
                    </div>
                    {roles &&
                      roles.filter(role => role.id !== 1).map((role) => (
                        <div key={role.id}>
                          <div
                            className="modal fade"
                            id={"modalChangePrivileges_" + role.id}
                            tabIndex="-1"
                            role="dialog"
                            aria-labelledby="modalChangePrivileges"
                            aria-hidden="true"
                          >
                            <div className="modal-dialog" role="document">
                              <div className="modal-content">
                                <div className="modal-body py-3">
                                  <div className="container-fluid">
                                    <button
                                      type="button"
                                      className="close"
                                      data-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                    <div className="row">
                                      <div className="col-12">
                                        <h5 className="mb-3 font-weight-700">
                                          Change Privileges
                                        </h5>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-12">
                                        <div className="section">
                                          {privileges &&
                                            privileges.map((privilege) => (
                                              <div key={privilege.id} className="form-group mt-1">
                                                <input
                                                  type="checkbox"
                                                  onChange={(event) =>
                                                    privilegeClicked(
                                                      privilege.id,
                                                      event.target.checked
                                                    )
                                                  }
                                                  id={
                                                    "privileges_" + privilege.id
                                                  }
                                                  checked={
                                                    assignedPrivileges &&
                                                    assignedPrivileges.some(
                                                      (a) =>
                                                        a.id === privilege.id
                                                    )
                                                  }
                                                />
                                                <label
                                                  className="checkbox checkbox-gray mb-0"
                                                  htmlFor={
                                                    "privileges_" + privilege.id
                                                  }
                                                >
                                                  {privilege.notes}
                                                </label>
                                              </div>
                                            ))}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-12 text-center mt-4">
                                        <button
                                          type="button"
                                          onClick={() => {
                                            setAssignedPrivileges([]);
                                          }}
                                          data-dismiss="modal"
                                          aria-label="Close"
                                          className="btn btn-grey btn-width-130 btn-height-40 border-2 font-size-14 mr-1"
                                        >
                                          CANCEL
                                        </button>
                                        <button
                                          type="button"
                                          data-dismiss="modal"
                                          onClick={() =>
                                            savePrivileges(role.id)
                                          }
                                          className="btn btn-blue btn-width-130 btn-height-40 border-2 font-size-14 ml-1"
                                        >
                                          UPDATE
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="modal fade"
                            id={"modalDelete_" + role.id}
                            tabIndex="-1"
                            role="dialog"
                            aria-labelledby="modalDelete"
                            aria-hidden="true"
                          >
                            <div className="modal-dialog" role="document">
                              <div className="modal-content">
                                <div className="modal-body">
                                  <div className="container-fluid">
                                    <div className="row justify-content-center">
                                      <div className="col-12 text-center">
                                        <h5 className="mb-2 pb-1">Delete role</h5>
                                      </div>
                                      <div className="col-12 text-center">
                                        <p className="mb-0">
                                          You are about to delete a role:{" "}
                                          <strong>{role.name}</strong>
                                        </p>
                                        <p className="mb-0">
                                          Are you sure you wish to continue?
                                        </p>
                                      </div>
                                      <div className="col-12 text-center mt-4">
                                        <button
                                          type="button"
                                          data-dismiss="modal"
                                          aria-label="Close"
                                          className="btn btn-grey btn-width-130 btn-height-40 border-2 font-size-14 mr-1"
                                        >
                                          CANCEL
                                        </button>
                                        <button
                                          type="button"
                                          data-dismiss="modal"
                                          onClick={() => removeRole(role.id)}
                                          className="btn btn-red btn-width-130 btn-height-40 border-2 font-size-14 ml-1"
                                        >
                                          DELETE
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="col-md-3 col-xl-2 pr-sm-0 align-self-center">
                              <p className="mb-2 mb-md-0 font-weight-500">
                                {role.name}
                              </p>
                            </div>
                            <div className="col-md-auto">
                              <button
                                type="button"
                                data-toggle="modal"
                                data-target={
                                  "#modalChangePrivileges_" + role.id
                                }
                                onClick={() => { clearRoleFields(); getAssignPrivileges(role.id) }}
                                className="btn btn-blue btn-width-80 btn-height-36 border-0 font-size-14 mr-1"
                              >
                                Change Privileges
                              </button>
                              <button
                                type="button"
                                data-toggle="modal"
                                data-target={"#modalDelete_" + role.id}
                                className="btn btn-red btn-box-36 border-0"
                              >
                                <img src={require("../img/bin.svg")} alt="" />
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                    <div className="row mt-4">
                      <div className="col-12">
                        <button
                          type="button"
                          data-toggle="modal"
                          data-target="#modalCreateRole"
                          onClick={() => clearRoleFields()}
                          className="btn btn-blue btn-width-130 btn-height-40 border-0 font-size-16"
                        >
                          Create a New Role
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-2 pt-1">
                <div className="col-12 chat-archive-table">
                  <div className="section background-white">
                    <div className="row py-2">
                      <div className="col align-self-center">
                        <p className="mb-0 font-size-14 line-height-18 pl-2 ml-1">
                          Team members
                        </p>
                      </div>
                      <div className="col-auto">
                        <span
                          className="link-download font-size-13 mr-2 pr-1"
                        >
                          <img
                            src={require("../img/download.svg")}
                            alt=""
                            className="mr-1"
                          />
                          Export (CSV)
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 d-none d-xl-inline">
                  <div className="section background-blue-light-4">
                    <div className="row">
                      <div className="col-lg-2 pr-0">
                        <div className="profile-box-blue profile-box-blue-bor-right">
                          <p className="mb-0 font-size-14 font-weight-500">Name</p>
                        </div>
                      </div>
                      <div className="col-lg-3 px-0">
                        <div className="profile-box-blue profile-box-blue-bor-right justify-content-center">
                          <p className="mb-0 font-size-14 font-weight-500">Email</p>
                        </div>
                      </div>
                      <div className="col-lg-2 px-0">
                        <div className="profile-box-blue profile-box-blue-bor-right justify-content-center">
                          <p className="mb-0 font-size-14 font-weight-500">
                            Locations
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-2 px-0">
                        <div className="profile-box-blue profile-box-blue-bor-right justify-content-center">
                          <p className="mb-0 font-size-14 font-weight-500">Role</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <EditUserModal
                  isModalOpen={isModalOpen}
                  editUserName={editUserName}
                  editUserEmail={editUserEmail}
                  editUserId={editUserId}
                  editUserRoleId={editUserRoleId}
                  origUserName={origUserName}
                  origUserRoleId={origUserRoleId}
                  origGroupIds={origGroupIds}
                  groupIds={groupIds}
                  roles={roles}
                  setEditUserName={setEditUserName}
                  setEditUserRoleId={setEditUserRoleId}
                  facilitiesChanged={facilitiesChanged}
                  setOrigGroupIds={setOrigGroupIds}
                  inviteUserById={inviteUserById}
                  updateUser={updateUser}
                  setIsModalOpen={setIsModalOpen}
                  displayInvitationSentLabel={displayInvitationSentLabel}
                />
                <div className="col-12 profile-category-wrap-move">
                  {users &&
                    users.map((user) => (
                      <div key={user.id}>
                        <div
                          className="modal fade"
                          id={"modalDeleteTeam_" + user.id}
                          tabIndex="-1"
                          role="dialog"
                          aria-labelledby="modalDeleteTeamM"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog" role="document">
                            <div className="modal-content">
                              <div className="modal-body">
                                <div className="container-fluid">
                                  <div className="row justify-content-center">
                                    <div className="col-12 text-center">
                                      <h5 className="mb-2 pb-1">
                                        Delete user - {user.name}?
                                      </h5>
                                    </div>
                                    <div className="col-12 text-center">
                                      <p className="mb-0">
                                        You are about to delete a team member{" "}
                                        {user.name} ({user.email}).
                                      </p>
                                      <p className="mb-0">
                                        Are you sure you wish to continue?
                                      </p>
                                    </div>
                                    <div className="col-12 text-center mt-4">
                                      <button
                                        type="button"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                        className="btn btn-grey btn-width-130 btn-height-40 border-2 font-size-14 mr-1"
                                      >
                                        CANCEL
                                      </button>
                                      <button
                                        type="button"
                                        onClick={() => removeUser(user.id)}
                                        data-dismiss="modal"
                                        className="btn btn-red btn-width-130 btn-height-40 border-2 font-size-14 ml-1"
                                      >
                                        DELETE
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className="section profile-category-wrap"
                          style={{ cursor: "auto" }}
                        >
                          <div className="row">
                            <div className="col-xl-2 pr-xl-0">
                              <div className="profile-box-white profile-box-white-bor-right">
                                <p className="mb-0 font-size-15">{user.name}</p>
                              </div>
                            </div>
                            <div className="col-xl-3 px-xl-0">
                              <div className="profile-box-white profile-box-white-bor-right justify-content-xl-center">
                                <p className="mb-0 font-size-15">{user.email}</p>
                              </div>
                            </div>
                            <div className="col-xl-2 px-xl-0">
                              <div className="profile-box-white profile-box-white-bor-right justify-content-xl-center">
                                {user.roleId !== 1 ? (<>
                                  <p className="mb-0 font-size-15 mr-2">
                                    Location(s)
                                  </p>
                                  <div
                                    className="q-mark relative-pos"
                                    data-toggle="tooltip"
                                    data-offset="0 5"
                                    title={user.locations}
                                  >
                                    ?
                                  </div></>) : (<p className="mb-0 font-size-15 mr-2">
                                    All Locations
                                  </p>)}
                              </div>
                            </div>
                            <div className="col-xl-2 px-xl-0">
                              <div className="profile-box-white profile-box-white-bor-right justify-content-xl-center">
                                <p className="mb-0 font-size-15 mr-2">
                                  {getRole(user.roleId)}
                                </p>
                                <div
                                  className="q-mark relative-pos"
                                  data-toggle="tooltip"
                                  data-offset="0 5"
                                  title={extractPrivileges(user.roleId)}
                                >
                                  ?
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-3 pl-xl-0">
                              <div className="profile-box-white profile-box-white-bor-right justify-content-xl-end">
                                {(user.status === "Created") && (
                                  <button
                                    type="button"
                                    className="btn btn-dark btn-width-80 btn-height-36 border-0 font-size-14 mr-1 mt-2 mt-xl-0"
                                    onClick={() =>
                                      inviteUser(
                                        user.email,
                                        user.roleId,
                                        user.name,
                                        user.id
                                      )
                                    }
                                  >
                                    Resend Invite
                                  </button>
                                )}
                                <button
                                  type="button"
                                  onClick={() => handleEditButtonClick(user)}
                                  className="btn btn-blue btn-width-80 btn-height-36 border-0 font-size-14 mr-1 mt-2 mt-xl-0"
                                >
                                  Edit
                                </button>
                                <button
                                  type="button"
                                  data-toggle="modal"
                                  data-target={"#modalDeleteTeam_" + user.id}
                                  className="btn btn-red btn-box-36 border-0 mt-2 mt-xl-0"
                                >
                                  <img src={require("../img/bin.svg")} alt="" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Team;