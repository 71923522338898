import React, { useState, useEffect, useRef } from "react";
import { ajaxCaller } from "../ajaxCaller";
import {
  getAccessToken,
  getCurrentUserAvatar,
  getCurrentUserName,
  getCurrentUserEmail,
  getCurrentUserId,
  getCurrentUserRoleId,
} from "../authentification";
import Cookies from "js-cookie";
import $ from "jquery";
import ErrorModal from "./components/errorModal";
import ChangePasswordModal from "./components/ChangePassword/ChangePasswordModal";
// Helpers
import { handleFileUpload, saveToCookies } from "./helpers/utils";
import { IMAGE_VALID_EXTENSIONS, IMAGE_ACCEPT_EXTENSIONS, IMAGE_MAX_SIZE_BYTES } from "./helpers/constants";
import { FaCheckCircle } from 'react-icons/fa';

const UserProfile = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [roleId] = useState(getCurrentUserRoleId());
  const [userName, setUserName] = useState(getCurrentUserName());
  const [userEmail, setUserEmail] = useState(getCurrentUserEmail());
  const [avatar, setAvatar] = useState(getCurrentUserAvatar());
  const [errorMessage, setErrorMessage] = useState("");
  const [errorTitle, setErrorTitle] = useState("");
  const clearError = () => {
    setErrorMessage("");
    setErrorTitle("");
  }

  const [modalMessage, setModalMessage] = useState("");
  const [notificationMessage, setNotificationMessage] = useState("");
  const [modalErrorMessage, setModalErrorMessage] = useState("");
  const [modalErrorMessageTitle, setModalErrorMessageTitle] = useState("");
  const clearModalMessage = () => {
    setModalMessage("");
    setModalErrorMessage("");
    setModalErrorMessageTitle("");
    setNotificationMessage("");
  }

  const fileInputRef = useRef(null);
  const [roles, setRoles] = useState(null);
  const getRoles = () => {
    ajaxCaller("Roles/GetRoles", {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + getAccessToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
    }).then((response) => {
      if (!response.isError) {
        setRoles(response);
      }
    });
  };
  const getRole = (roleId) => {
    var role =
      roles && roles.find((x) => x.id.toString() === roleId.toString());

    if (role) {
      return role.name;
    }
  };
  useEffect(() => {
    clearError();
    getRoles();
  }, []);

  const getUser = () => {
    ajaxCaller("User/GetUser", {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + getAccessToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
    }).then((response) => {
      if (!response.isError) {
        setAvatar(response.avatar);
      }

    });
  };

  useEffect(() => {
    clearError();
    getUser();
  }, []);

  const uploadAvatar = (file) => {
    var data = new FormData();
    data.append("file", file);
    // Make the AJAX call to upload the avatar
    ajaxCaller("User/UploadAvatar", {
      method: "post",
      headers: new Headers({
        Authorization: "Bearer " + getAccessToken(),
      }),
      body: data,
    }).then((response) => {
      if (!response.isError) {
        Cookies.set("user_avatar", response.fileName);
        document.getElementById("nav-bar-avatar").setAttribute("src", "https://kontakstorage.blob.core.windows.net/avatars/" + response.fileName);
        setAvatar(response.fileName);
      } else {
        clearError();
        // Handle errors from the server (if applicable)
        setErrorMessage(response.message || "Failed to upload avatar.");
        // Reset the file input value
        if (fileInputRef.current) {
          fileInputRef.current.value = ""; // Clear the file input
        }
      }
    });
  };

  const updateUser = () => {
    ajaxCaller("User/EditUser", {
      method: "put",
      headers: new Headers({
        Authorization: "Bearer " + getAccessToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
      body: JSON.stringify({
        id: getCurrentUserId(),
        name: userName,
        email: userEmail,
        roleId: roleId,
      }),
    }).then((response) => {
      if (!response.isError) {
        getUser();
        document.getElementById("nav-bar-userName").textContent = response.name;
        Cookies.set("user_name", response.name);
        Cookies.set("user_email", response.email);
      }
    });
  };

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const extractPrivileges = (roleId) => {
    var role = roles && roles.find(x => x.id === roleId);
    return role && role.privileges;
  }

  useEffect(() => {
    $('[data-toggle="tooltip"]').tooltip();
    $('[data-toggle-second="tooltip"]').tooltip();
  });

  const [oldPassword, setOldPassword] = useState(null);
  const [newPassword, setNewPassword] = useState(null);
  const [newPasswordConfirm, setNewPasswordConfirm] = useState(null);
  const [logMeOut, setLogMeOut] = useState(false);

  const changePassword = () => {
    ajaxCaller("User/ChangePassword", {
      method: "put",
      headers: new Headers({
        Authorization: "Bearer " + getAccessToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
      body: JSON.stringify({
        oldPassword: oldPassword,
        newPassword: newPassword,
        logMeOut: logMeOut,
      }),
    })
      .then((response) => {
        if (response.isError) {
          // Handle errors early and exit
          clearModalMessage();
          setTimeout(() => {
            if (response.message === "Invalid Password") {
              setModalErrorMessageTitle("Current Password Incorrect!");
              setModalErrorMessage(
                "The current password you entered is incorrect!\nPlease try again or reset your password if needed."
              );
            } else {
              setModalErrorMessageTitle("Password Update Failed");
              setModalErrorMessage(
                "Something went wrong while updating your password!\nPlease try again or contact support if the issue continues."
              );
            }
          }, 0);
          return;
        }
        // Success flow
        saveToCookies(response);
        clearModalMessage();
        setModalMessage("Successful Password Update!");

        setTimeout(() => {
          setIsModalOpen(false);
          setNotificationMessage(
            `Thank you for updating your password!${logMeOut
              ? "\nYou have been successfully logged out of all accounts and devices."
              : ""
            }`
          );

          setTimeout(() => {
            setNotificationMessage("");
            resetFields();
          }, 5000);
        }, 1000);
      })
      .catch((error) => {
        setModalErrorMessageTitle("Unexpected Error");
        setModalErrorMessage(
          "An unexpected error occurred while updating your password. Please try again later."
        );
      });
  };


  const [featureFlags, setFeatureFlags] = useState();
  const getFeatureFlags = async (name, context) => {
    try {
      ajaxCaller("RolloutFeatureFlags/ToggleFlags", {
        method: "POST",
        headers: new Headers({
          Authorization: "Bearer " + getAccessToken(),
          "Content-Type": "application/json",
          Accept: "application/json",
        }),
        body: JSON.stringify({
          name,
          context
        }),
      }).then((response) => {
        if (!response.isError) {
          setFeatureFlags(response.isEnabled);
        }
        else {
          setErrorMessage(`Failed to fetch feature flags ${response.message}`);
        }
      });
    } catch (error) {
      console.error('Failed to fetch feature flags:', error);
    }
  }

  const resetFields = () => {
    setOldPassword(""); // Reset old password field
    setNewPassword(""); // Reset new password field
    setNewPasswordConfirm(""); // Reset confirm password field
    clearError();
    clearModalMessage()
    setLogMeOut(false); // Reset the checkbox
  };


  useEffect(() => {
    clearError();
    getFeatureFlags('EnableSessionInvalidation')
  }, []);

  return (
    <>
      {<ErrorModal errorTitle={errorTitle} errorMessage={errorMessage}></ErrorModal>}
      <div className="section sec-move-left">
        <div className="section py-5 px-lg-2">
          <div className="section px-1">
            <div className="container-fluid z-bigger-10">
              <div className="row mt-3 mt-lg-4">
                <div className="col-12">
                  <h5 className="mb-2 pb-1">User Profile</h5>
                </div>
                <div className="col-12">
                  <div className="section background-white p-3 p-lg-4">
                    <div className="row">
                      <div className="col-md-2 col-xl-1 align-self-center">
                        <p className="mb-2 mb-md-0 font-weight-500">Image</p>
                      </div>
                      <div className="col-md">
                        <div className="section avatar-upload account-upload">
                          <div className="container-avatar">
                            <div className="imageWrapper">
                              <img
                                className="avatar-image"
                                src={

                                  avatar === "" || avatar == null || avatar === " " || avatar === "null"
                                    ? ("https://kontakstorage.blob.core.windows.net/avatars/defaultLogo.svg")
                                    : ("https://kontakstorage.blob.core.windows.net/avatars/" + avatar)
                                }
                                alt="avatar"
                              />
                            </div>
                          </div>
                          <div className="file-upload align-self-center">
                            <input
                              type="file"
                              accept={IMAGE_ACCEPT_EXTENSIONS}
                              className="file-input"
                              onClick={() => clearError()}
                              ref={fileInputRef}
                              onChange={(event) => handleFileUpload(
                                event,
                                uploadAvatar,
                                setErrorMessage,
                                clearError,
                                IMAGE_VALID_EXTENSIONS,
                                IMAGE_MAX_SIZE_BYTES,
                                1000,      // Set custom max height
                                1000       // Set custom max width
                              )}
                            />
                            Choose File
                          </div>
                        </div>
                      </div>
                      <div className="w-100"></div>
                      <div className="col-md offset-md-2 offset-xl-1 mt-1">
                        <p className="mb-0 font-size-13 line-height-18 opacity-70">
                          <i>
                            Ideal dimensions are 200px x 200px.
                            <br />
                            Maximum file size is 1mb.
                            <br />
                            Valid extensions:{IMAGE_ACCEPT_EXTENSIONS}
                          </i>
                        </p>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-2 col-xl-1 align-self-center">
                        <p className="mb-2 mb-md-0 font-weight-500">Name</p>
                      </div>
                      <div className="col-md">
                        <div className="form-group max-260">
                          <input
                            type="text"
                            className="form-style border-0 small-40"
                            placeholder="Your Name..."
                            onChange={(event) => setUserName(event.target.value)}
                            value={userName}
                            autoComplete="off"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-2 col-xl-1 align-self-center">
                        <p className="mb-2 mb-md-0 font-weight-500">Email</p>
                      </div>
                      <div className="col-md">
                        <div className="form-group max-260">
                          <input
                            type="email"
                            className="form-style border-1 small-40"
                            placeholder="Your Email..."
                            onChange={(event) => setUserEmail(event.target.value)}
                            value={userEmail}
                            autoComplete="off"
                          />
                        </div>
                      </div>
                    </div>
                    {/* Password Section */}
                    <div className="row mt-3">
                      <div className="col-md-2 col-xl-1 align-self-center">
                        <p className="mb-2 mb-md-0 font-weight-500">Password</p>
                      </div>
                      <div className="col-md">
                        <p className="mb-0 font-size-14">
                          <span
                            style={{ color: "#19A3FC", cursor: "pointer" }}
                            onClick={() => setIsModalOpen(true)}
                          >
                            Change your password
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-2 col-xl-1 align-self-center">
                        <p className="mb-2 mb-md-0 font-weight-500">Role</p>
                      </div>
                      <div className="col-md-auto">
                        <p className="mb-0 font-size-15">
                          {roleId > 1 ? getRole(roleId) : "Super Admin"}
                        </p>
                      </div>
                      <div className="col-xl-2 px-xl-0" style={{ marginTop: "4px" }}>
                        {roles && (<div
                          className="q-mark relative-pos"
                          data-toggle="tooltip"
                          data-offset="0 5"
                          data-placement="top"
                          title={extractPrivileges(roleId)}
                        >
                          ?
                        </div>)}
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-12">
                        <button
                          onClick={() => updateUser()}
                          disabled={(!validateEmail(userEmail) || userName == null || userName === "" || userName === " ") || (getCurrentUserName() === userName && getCurrentUserEmail() === userEmail)}
                          className="btn btn-blue btn-width-130 btn-height-40 border-0 font-size-16"
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ChangePasswordModal
          isModalOpen={isModalOpen}
          featureFlags={featureFlags}
          oldPassword={oldPassword || ''}
          newPassword={newPassword || ''}
          newPasswordConfirm={newPasswordConfirm || ''}
          logMeOut={logMeOut}
          modalErrorMessageTitle={modalErrorMessageTitle}
          modalErrorMessage={modalErrorMessage}
          modalMessage={modalMessage}
          setOldPassword={setOldPassword}
          setNewPassword={setNewPassword}
          setNewPasswordConfirm={setNewPasswordConfirm}
          setLogMeOut={setLogMeOut}
          resetFields={resetFields}
          changePassword={changePassword}
          setIsModalOpen={setIsModalOpen}
        />
      </div>


      {/* Notification on underlying page */}
      {notificationMessage && (
        <div className="alert-success notification" role="alert" style={{ whiteSpace: "pre-line" }}>
          <FaCheckCircle style={{ marginRight: '8px', fontSize: '1.2rem', color: '#04816B' }} />
          {notificationMessage}
        </div>
      )}
    </>
  );
};


export default UserProfile;
