import { apiUrl } from "./apiServer";
import { getAccessToken } from "./authentification";
import { logoutUtility } from './app/helpers/utils';

export const ajaxCaller = (url, parameters) => {
  return ajaxNonJsonCaller(url, parameters).then(response => {
    return response.json();
  });
};

export const ajaxNonJsonCaller = (url, parameters) => {
  const globalSpinner = document.getElementById("globalSpinner");
  if (globalSpinner != null) {
    globalSpinner.style.display = "block";
  }

  return fetch(apiUrl + url, parameters).then(response => {
    if (globalSpinner != null) {
      globalSpinner.style.display = "none";
    }
    // Handle 401 Unauthorized
    if (response.status === 401) {
      logoutUtility();
      return Promise.reject("Unauthorized - Redirecting to login");
    }

    return response;
  }).catch(error => {
    if (globalSpinner != null) {
      globalSpinner.style.display = "none";
    }
    console.error("Error in ajax call:", error);
    throw error;
  });
};

export const authHeaders = new Headers({
  Authorization: "Bearer " + getAccessToken(),
  "Content-Type": "application/json",
  Accept: "application/json"
});

export const authHeadersForFiles = new Headers({
  Authorization: "Bearer " + getAccessToken()
}
);
